import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom"
import classes from './ProductPage.module.css';
import {data} from '../../data/data';
import { Page } from 'types/types';

export default function ProductPage() {
  useEffect(() => {
    document.title = 'Официальный дилер по продаже складской и строительной техники | АиСТ-С';
  }, []);  
    const {id} = useParams();
    const param = id;
    let dataP: Page = {};
    for (let i = 0; i < data.length; i+=1) {
      const d1 = data[i].pages;
      for (let j = 0; j < d1.length; j+=1) {
        const d2 = d1[j];
        if (d2.url == param) {
          dataP = d2;
        }
      }
    }
  return (
    <main className={classes.catalog_product__container}>
        <Helmet>
          {/* <title>{dataP?.titleProduct} | АиСТ-С</title> */}
          <meta
          name="keywords"
          content={`${dataP.titleProduct?.toLowerCase()}, ${dataP.titleProduct?.toLowerCase()} в Саратове, аист-с, aist-c, запчасти для погрузчиков, погрузчики лонкинг в саратове, вилочные погрузчики, погрузчики в саратове, аист-с в саратове, вилочные погрузчики в саратове, купить погрузчики лонкинг в саратове, Бирюзова 27, LONKING`}
          />
          <meta
          name="description"
          content={`Каталог (${dataP?.titleProduct}) - ООО "АиСТ-С" - официальный дилер спецтехники Лонкинг.`}
          />
        </Helmet>
        <h3 className={classes.catalog_product_title}>{dataP?.titleProduct}</h3>
        <div className={classes.catalog_product_wrapper}>
          <img src = {dataP?.imgProduct} alt = {dataP?.titleProduct} className={classes.catalog_product_img}/>
          <div className={classes.catalog_product__description_wrapper}>
          <span className={classes.catalog_product__description_title_text}>Технические характеристики</span>
            <ul className={classes.catalog_product__description}>
              {dataP.description?.manufacturer ? <li className={classes.catalog_product__description_text}><i>Производитель: </i><strong>{dataP.description.manufacturer}</strong></li> : null}
              {dataP.description?.loadСapacity ? <li className={classes.catalog_product__description_text}><i>Грузоподъемность: </i><strong>{dataP.description.loadСapacity}</strong></li> : null}
              {dataP.description?.TippingLoad ? <li className={classes.catalog_product__description_text}><i>Нагрузка опрокидывания: </i><strong>{dataP.description.TippingLoad}</strong></li> : null}
              {dataP.description?.OperatingWeight ? <li className={classes.catalog_product__description_text}><i>Эксплуатационная масса: </i><strong>{dataP.description.OperatingWeight}</strong></li> : null}
              {dataP.description?.UnloadingHeight ? <li className={classes.catalog_product__description_text}><i>Высота выгрузки (по шарниру ковша): </i><strong>{dataP.description.UnloadingHeight}</strong></li> : null}
              {dataP.description?.Engine ? <li className={classes.catalog_product__description_text}><i>Двигатель: </i><strong>{dataP.description.Engine}</strong></li> : null}
              {dataP.description?.EnginePower ? <li className={classes.catalog_product__description_text}><i>Мощность двигателя: </i><strong>{dataP.description.EnginePower}</strong></li> : null}
              {dataP.description?.BucketWidth ? <li className={classes.catalog_product__description_text}><i>Ширина ковша: </i><strong>{dataP.description.BucketWidth}</strong></li> : null}
              {dataP.description?.TotalCycleTime ? <li className={classes.catalog_product__description_text}><i>Общее время цикла: </i><strong>{dataP.description.TotalCycleTime}</strong></li> : null}
              {dataP.description?.TotalLength ? <li className={classes.catalog_product__description_text}><i>Общая длина: </i><strong>{dataP.description.TotalLength}</strong></li> : null}
              {dataP.description?.TurningRadius ? <li className={classes.catalog_product__description_text}><i>Радиус поворота по внешней кромке ковша: </i><strong>{dataP.description.TurningRadius}</strong></li> : null}
              {dataP.description?.ForwardSpeed ? <li className={classes.catalog_product__description_text}><i>Скорость движения вперед: </i><strong>{dataP.description.ForwardSpeed}</strong></li> : null}
              {dataP.description?.BackSpeed ? <li className={classes.catalog_product__description_text}><i>Скорость движения назад: </i><strong>{dataP.description.BackSpeed}</strong></li> : null}
              {dataP.description?.HydraulicFlow ? <li className={classes.catalog_product__description_text}><i>Гидравлический поток: </i><strong>{dataP.description.HydraulicFlow}</strong></li> : null}
              {dataP.description?.TireSize ? <li className={classes.catalog_product__description_text}><i>Размер шин: </i><strong>{dataP.description.TireSize}</strong></li> : null}
              {dataP.description?.Control ? <li className={classes.catalog_product__description_text}><i>Управление: </i><strong>{dataP.description.Control}</strong></li> : null}
              {dataP.description?.FuelCapacity ? <li className={classes.catalog_product__description_text}><i>Объем топливного бака: </i><strong>{dataP.description.FuelCapacity}</strong></li> : null}
              {dataP.description?.BucketIncluded ? <li className={classes.catalog_product__description_text}><i>Объем ковша в комплекте: </i><strong>{dataP.description.BucketIncluded}</strong></li> : null}
              {dataP.description?.DrivingSpeed ? <li className={classes.catalog_product__description_text}><i>Скорость движения: </i><strong>{dataP.description.DrivingSpeed}</strong></li> : null}
            </ul>
          </div>
        </div>
        {
          (dataP.description?.manufacturer == 'LONKING') ? 
          <div className={classes.catalog__description_lonking__wrapper}>
          <div className={classes.catalog__description_lonking}>
            <strong>Lonking</strong> - это мировой лидер в производстве строительной техники, известный своими инновационными разработками и надежными решениями. 
          </div>
          <div className={classes.catalog__description_lonking}>
            Lonking предлагает широкий ассортимент строительной техники. Каждая модель отличается высокой производительностью, превосходным качеством и надежностью. Благодаря применению передовых технологий и инновационных решений, техника Лонкинг обладает высокой эффективностью и способна справиться с самыми сложными условиями и задачами.
          </div>
          <div className={classes.catalog__description_lonking}>
            Мы не только предлагаем высококачественную технику Lonking, но и обеспечиваем полное сервисное обслуживание, поставку запчастей и техническую поддержку. Мы стремимся установить долгосрочные партнерские отношения с клиентами, предлагая им только самое лучшее.
          </div>
        </div>
          : (dataP.description?.manufacturer == 'SHANMON') ?
          <div className={classes.catalog__description_lonking__wrapper}>
          <div className={classes.catalog__description_lonking}>
            <strong>Shanmon</strong> - Компания, основным видом деятельности которой, является производство специальной техники, широко применяемой в строительной отрасли и активно используемой коммунальными предприятиями для решения сложных задач.
          </div>
          <div className={classes.catalog__description_lonking}>
          Экскаваторы-погрузчики — многоцелевой транспорт, который применяется во многих областях и отраслях из-за универсальности и способности выполнять разнообразные задачи.
          </div>
          <div className={classes.catalog__description_lonking}>
          Рассматриваемая техника универсальна. Ее применение облегчает выполнение строительных, транспортных и обслуживающих задач.
          </div>
        </div>
        : null
        }
    </main>
  );
}